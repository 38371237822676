<template>
    <el-carousel :indicator="indicator" :height="height">
      <el-carousel-item v-for="(item,i) in images" :key="i">
        <el-image style="width: 100%;height: 100%"   fit="cover"  :src="item"></el-image>
      </el-carousel-item>
    </el-carousel>
</template>
<script>
export default {
  name: "carousel",
  props:{
    images:{
      type:Array
    },
    height:"",
    indicator:""
  }
}
</script>
<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}



.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
