<template>
  <el-container>
    <el-header style="padding: 0" height="190px">
      <template>
        <heard></heard>
      </template>
    </el-header>
    <el-main style="padding: 0">
      <template>
        <div style="border-top: 10px solid deepskyblue;width: 100%">
          <carousel :images="images" height="450px"></carousel>
        </div>
      </template>
      <template>
        <div style="background-color: #eeeeee;height: 50px">
          <ul style="list-style: none;display: flex;flex-direction: row;line-height: 50px"
              class="basic_container_noflex">
            <li :class="{active_tag:active===i}" style="width: 120px" v-for="(item,i) in child_menu" :key="i"
                @click="go_link(i,item.path)">{{ item.name }}
            </li>
          </ul>
        </div>
      </template>
      <template>
        <router-view/>
      </template>
      <template>
        <top-tag></top-tag>
      </template>
      <template>
        <top-message></top-message>
      </template>
    </el-main>
  </el-container>
</template>

<script>

import Heard from "@/components/Heard";
import Carousel from "@/components/Carousel";
import TopTag from "@/components/TopTag";
import TopMessage from "@/components/TopMessage";
import {httpAction} from "@/api/manage";

export default {
  name: "index",
  components: {
    Heard,
    Carousel,
    TopTag,
    TopMessage
  },
  data() {
    return {
      active: 0,
      indexView: {},
      companyInfo: {},
      child_menu: [
        {name: "公司简介", path: "/aboutus/companyProfile"},
        {name: "企业文化", path: "/aboutus/corporateCulture"},
        // {name:"资质荣誉",path:"/aboutus/companyOver"},
        // {name:"招贤纳士",path:"/aboutus/companyOver"},
      ],
      images: [],
      urls: {
        initIndex: "/index/getIndexConfig",
        companyInfo: "/companyManage/getCompanyInfo"
      }
    }
  },
  created() {
    this.initIndexView()
  },
  methods: {
    initIndexView() {
      httpAction(this.urls.initIndex, "", "get").then(res => {
        if (res.code == 0) {
          Object.assign(this.indexView, res.data)
          if (this.indexView.indexImages) {
            this.images = this.indexView.indexImages.map((item, index) => {
              return item.fileUrl
            })
          }
          return httpAction(this.urls.companyInfo, {body: {}});
        }
      }).then(res1 => {
        if (res1.code == 0) {
          Object.assign(this.companyInfo, res1.data)
        }
      })
    },
    go_link(i, path) {
      this.active = i
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.active_tag {
  color: #3a8ee6;
}

.border_left_line {
  border-right: 1px solid gray;
}
</style>
